.pointer {
  cursor: pointer;
}

.summary {
  background-color: #fff7d6 !important;
}

.htDimmed {
  background-color: #e8f4ff !important;
  color: #000000 !important;
  font-weight: bold !important;
}
